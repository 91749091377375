import React from 'react';
import JobDescriptionOverview from "../../entitites/raet/jobDescriptionOverview";
import JobDescriptionEdit from "../../entitites/raet/jobDescriptionEdit";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/reducers";
import FileUploader from "../../features/fileUploader/fileUploader";
import {behindAPI} from "../../app";
import {setJobDescriptionMode, setProjectSelection} from "../../app/reducers/raet/raetReducer";
import {setSipContactDispatch} from "../../app/reducers/sipReducer";
import phoneIconWhite from "../../shared/assets/img/phone.png";
import {PhoneIcon} from "@heroicons/react/24/solid";
import PWButton from "../../shared/ui/btn/PhonewiseButton";

const JobDescriptionPage = () => {
    const dispatch = useDispatch();
    const tabSelection = useSelector((state: RootState) => state.raet.tabSelection);
    const jobDescriptionMode = useSelector((state: RootState) => state.raet.jobDescriptionMode);
    const project = useSelector((state: RootState) => state.raet.projectSelection);
    const [jobDescription, setJobDescription] = React.useState('');
    const [projectPending, setProjectPending] = React.useState(false);

    const onCreateJobDescription = async () => {
        try {
            setProjectPending(true);
            const parse = await behindAPI.RaetJdParse(project?._id, jobDescription);
            if (parse.success) {
                const update = await behindAPI.RaetJdUpdate(project?._id, parse.data?.jd);
                if (update.success) {
                    const res = await behindAPI.RaetProjectGet(project?._id);
                    if (res?.success) {
                        dispatch(setProjectSelection(res?.data))
                        setProjectPending(false);
                    }
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div>
            {/*{tabSelection}*/}
            {jobDescriptionMode === 'edit' ?
                <>
                    <JobDescriptionEdit/>
                </>
                :
                <>
                    <div className='bg-blue-500 rounded-t-3xl h-48'
                         style={{ position: 'relative',
                        // @ts-ignore
                        backgroundImage: `url(${project?.image?.header})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}/>
                    <div className='flex px-8 pt-5 pb-4 items-center justify-between'>
                        <div className=''>
                            <span className='text-2xl pr-7 font-bold'>{project?.name}</span>
                            <span className='font-bold'>{project?.cv_count}</span>
                            <span className='text-slate-400 pr-5 pl-1'>Resumes</span>
                            <span className='font-bold'>{project?.call_count}</span>
                            <span className='text-slate-400 pl-1'>Calls</span>
                        </div>
                        <div>
                            {(project?.status !== 'pending' && !projectPending) && (
                                <div className={'flex'}>
                                <PWButton
                                    type="secondary"
                                    onClick={() => dispatch(setJobDescriptionMode('edit'))}
                                    className={'mr-3'}
                                >
                                    Edit
                                </PWButton>
                                <PWButton
                                onClick={() => dispatch(setSipContactDispatch({phone: '', name: ' ', meta: {project_id:project?._id}}))}
                                >
                                <PhoneIcon style={{width: 18, color: 'white'}} className={'inline-block mr-2'}/> Call
                                </PWButton>
                                </div>
                            )}
                        </div>
                    </div>
                    {(project?.status === 'pending' || projectPending) ?
                        <div className={'flex flex-col justify-center items-center py-10 px-2'}>
                            <div>
                                <div role="status" className={'mb-4'}>
                                    <svg aria-hidden="true"
                                         className="inline w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-violet-600"
                                         viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="currentColor"/>
                                        <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentFill"/>
                                    </svg>
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                            <div className={'text-base text-black/60'}>
                                <i>The project is updating. Please wait...</i>
                            </div>
                        </div>
                        :
                        <>
                            <div className='px-8 pb-8 text-sm leading-6'>
                                {project?.description}
                            </div>
                            {/*<div className="flex justify-center items-center">*/}
                            {/*    <PWButton*/}
                            {/*        onClick={() => dispatch(setSipContactDispatch({phone: '', name: ' ', meta: {project_id:project?._id}}))}*/}
                            {/*    >*/}
                            {/*        <PhoneIcon style={{width: 18, color: 'white'}} className={'inline-block mr-2'}/> Start calling*/}
                            {/*    </PWButton>*/}
                            {/*</div>*/}
                            <JobDescriptionOverview/>
                            {project && project.jd && Object.keys(project.jd).length > 0 ?
                                <></>
                                :
                                <div className="my-4 mx-8">
                        <textarea
                            className="w-full border border-gray-300 rounded-lg p-2"
                            placeholder="Project Description"
                            rows={20}
                            value={jobDescription}
                            onChange={(e) => setJobDescription(e.target.value)}
                        />
                                    <div className='flex mt-4 w-full'>
                                        <button className="w-full lg:w-auto bg-blue-500 text-white rounded-lg px-4 py-2 hover:bg-blue-600"
                                                style={{width: '100%'}}
                                                onClick={() => (onCreateJobDescription())}
                                        >
                                            Create job description
                                        </button>
                                    </div>

                                </div>
                            }
                        </>
                    }

                </>

            }
            {/*{JSON.stringify(project)}*/}
        </div>
    );
};

export default JobDescriptionPage;