import React, { useEffect, useState } from 'react';
import './sipLiveTranscript.scss';
import { io } from "socket.io-client";
import {setJobDescriptionMode} from "../../app/reducers/raet/raetReducer";
import {SparklesIcon, ArrowUpRightIcon} from "@heroicons/react/24/outline";
import {setSipContactDispatch} from "../../app/reducers/sipReducer";
import OpenInFullOutlinedIcon from '@mui/icons-material/OpenInFullOutlined';

// @ts-ignore
const SipLiveTranscript = ({messageHistory}) => {
    return (
            <div className='sipWidget__transcript__container w-full border-l border-white/10 text-white my-7 px-8 flex flex col'
            style={{height: 'calc(100% - 100px)'}}
            >
            {/*{JSON.stringify(messageHistory)}*/}
            <div className='h-3/6 max-h-3/6 shrink-0 flex overflow-scroll flex-col'
                 style={{minHeight: '50%'}}
            >
                {/*// @ts-ignore*/}
                {messageHistory?.map((entry, index) => (
                    <div key={index}>
                        {/*//@ts-ignore  */}
                        <strong className={'text-white/30'}>{entry.message.role === 'host' ? 'You' : entry.message.role}:&nbsp;</strong>{entry.message.text}
                    </div>
                ))}
            </div>
            <div className='border-t border-white/10 mt-2 pt-2 h-3/6 max-h-3/6 shrink-0 flex overflow-scroll flex-col'
                 style={{minHeight: '50%'}}
            >
                <div>
                    <div>
                        Could you walk us through how you would design and implement a small application that tracks user activities on a website in real time?
                    </div>
                    <div>
                        Describe a complex problem you’ve faced in your previous projects. How did you approach solving it? What was the outcome?
                    </div>
                    <div>
                        What technologies are you most comfortable working with? Can you discuss a project where you utilized these technologies extensively?
                    </div>
                </div>
                <div className='flex justify-center mt-5 mb-4'>
                    <button className="bg-white/10 text-white rounded-lg px-6 py-1 hover:bg-white/20"
                    >
                        <SparklesIcon style={{width: 16, color: 'white', display: "inline-block"}} className={'mr-2'}/>Get AI suggestions
                    </button>
                </div>

            </div>

        </div>

    );
};

export default SipLiveTranscript;
