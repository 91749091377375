import React, { useState, useRef, FC, useEffect } from 'react';
import noteIcon from "../../../shared/assets/img/sticky-note.png";
import { useDispatch, useSelector } from "react-redux";
import { behindAPI } from "../../../app";
import { RootState } from "../../../app/reducers";
import './contactGrid.scss'
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { setMobileTransitionDispatch } from "../../../app/reducers/appearanceReducer";
import { Box, Modal } from "@mui/material";
import LoginForm from "../../../entitites/loginForm/loginForm";
import { setChatId } from "../../../app/reducers/chatReducer";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import {
    contactUploadStatusDispatcher,
    currentContactDispatcher,
    setContactIds,
    setCurrentContactId
} from "../../../app/reducers/currentContact";
import StringAvatar from "../../../shared/lib/StringAvatar";
import PWButton from "../../../shared/ui/btn/PhonewiseButton";

type Company = {
    _id: string;
    name: string;
    tags: Array<string>
    cv: { rank: number}
    categories: Array<{ name: string }>;
    phones: Array<{ number: string }>;
    description: string;
    description_ext: string;
    contacts: { homepage: string };
};

interface ContactTableProps {

}

const ContactTable: FC<ContactTableProps> = () => {
    const dispatch = useDispatch();
    const { trackPageView, trackEvent } = useMatomo();
    const createGroup = useSelector((state: RootState) => state.appearance.createGroup);
    const editGroup = useSelector((state: RootState) => state.appearance.editGroup);
    const editGrid = useSelector((state: RootState) => state.appearance.editGrid);
    const isAuth = useSelector((state: RootState) => state.user.isAuth);
    const searchValue = useSelector((state: RootState) => state.company.filter_keyword);
    const filterData = useSelector((state: RootState) => state.company.filter);
    const groupID = useSelector((state: RootState) => state.company.group_id?._id);
    const user_role = useSelector((state: RootState) => state.user.userRole);
    const company_ids = useSelector((state: RootState) => state.company.company_ids);
    const route = useSelector((state: RootState) => state.app.currentApp);
    const uploadStatus = useSelector((state: RootState) => state.contact.uploadStatus);
    const currentProject = useSelector((state: RootState) => state.raet.projectSelection);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [companyCounter, setCompanyCounter] = useState(0);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 50,
    });
    const [rowData, setRowData] = useState<Company[]>([]);
    const currentCompany = useRef<Company | null>(null);
    const companiesArr = useRef<Company[]>([]);
    const routerCompanyId = useParams();
    const [rowSelectionModel, setRowSelectionModel] = useState<string[]>([]);
    const [guestLimit, setGuestLimit] = useState(false);

    useEffect(() => {
        (async () => {
            if (groupID === 'all' || groupID === 'create') {
                console.log('currentProject', currentProject);
                await contactData(searchValue, filterData, paginationModel.page, paginationModel.pageSize);
            } else {
                await groupData(groupID, paginationModel.page, paginationModel.pageSize);
            }
            dispatch(setContactIds([]));
            setRowSelectionModel([]);
        })();
    }, [groupID, editGroup, createGroup, user_role]);

    useEffect(() => {
        (async () => {
            await contactData(searchValue, filterData, 0, 50);
        })();
    }, [searchValue, filterData, user_role]);

    useEffect(() => {
        (async () => {
            (uploadStatus === 'success' || uploadStatus === 'deleted') && await contactData(searchValue, filterData, 0, 50);
        })();
    }, [uploadStatus]);

    async function groupData(name: string, page: number, pageSize: number) {
        setIsLoading(true);
        const companies = await behindAPI.SalesGroupGet(name, page, pageSize);
        companiesArr.current = companies?.data;
        setCompanyCounter(parseInt(companies.count));
        setRowData(companiesArr.current);
        setIsLoading(false);
    }

    async function contactData(query: string, filter:any, page: number, pageSize: number) {
        setIsLoading(true);
        let companies;
        if (user_role === 'guest') {
            setGuestLimit(true);
            return;
        } else if (user_role === 'user') {
            companies = await behindAPI.RaetIndividualsGet(currentProject?._id, query, page, pageSize);
            if (companies.success === true) {
                dispatch(contactUploadStatusDispatcher('updated'));
            }
        }
        companiesArr.current = companies?.data;
        setCompanyCounter(parseInt(companies.count));
        setRowData(companiesArr.current);
        setIsLoading(false);
    }


    const navigate = useNavigate();

    const transformCompanyName = (name: any) => {
        return name
            .replace(/&/g, 'and')
            .replace(/[åä]/g, 'a')
            .replace(/ö/g, 'o')
            .replace(/é/g, 'e')
            .replace(/[.,]/g, '-')
            .replace(/[^\w-]|_/g, '-')
            .replace(/-{2,}/g, '-');
    };

    const navigateAndDispatch = (companyId: string) => {
        if (route === 'sales' || route === 'sales-uae') {
            navigate("/companies/" + companyId + "-" + transformCompanyName(currentCompany?.current?.name));
        }
        dispatch(setChatId(companyId));
    };

    const handleRowClick = async (company: Company) => {
        trackEvent({ category: 'companies-list', action: 'company-row-click' });
        currentCompany.current = company;
        const res = await behindAPI.RaetCvGet(currentCompany.current?._id);
        if (res.success === true) {
            dispatch(currentContactDispatcher(res.data));
            dispatch(setCurrentContactId(currentCompany.current?._id));
            navigateAndDispatch(currentCompany.current?._id || '');
        }
        dispatch(setMobileTransitionDispatch(1));
    };

    async function getNewPage(newPage: number, pageSize: number) {
        if (user_role === 'guest' && newPage > 0) {
            setGuestLimit(true);
            return;
        }
        setPage(newPage);
        if (groupID === 'all' || groupID === '') {
            await contactData(searchValue, filterData, newPage, pageSize);
        } else {
            await groupData(groupID, paginationModel.page, paginationModel.pageSize);
        }
    }

    useEffect(() => {
        (async () => {
            await getNewPage(paginationModel.page, paginationModel.pageSize);
        })();
    }, [paginationModel]);

    useEffect(() => {
        if (company_ids.length < 1) {
            setRowSelectionModel(company_ids);
        }
    }, [company_ids]);

    return (
        <div className="w-full h-full flex flex-col">
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white">
                    <thead>
                    <tr>
                        <th className="px-4 py-2 w-3/5">Contact</th> {/* 60% */}
                        <th className="px-4 py-2 w-3/10">Skills</th> {/* 30% */}
                        <th className="px-4 py-2 w-1/10">Rank</th> {/* 10% */}
                    </tr>
                    </thead>
                    <tbody>
                    {rowData.map((contact) => (
                        <tr
                            key={contact._id}
                            onClick={() => handleRowClick(contact)}
                            className="hover:bg-gray-100 cursor-pointer"
                        >
                            <td className="px-4 py-2 w-3/5"> {/* 60% */}
                                <div className="flex items-center">
                                    {/*@ts-ignore*/}
                                    <StringAvatar name={contact.name ? contact.name : 'NN'} />
                                    <div className="pl-4">
                                        {/*@ts-ignore*/}
                                        <div className="text-base font-medium text-gray-900">{contact.name}</div>
                                        {/*@ts-ignore*/}
                                        <div className="text-sm text-gray-500">
                                            {/*{contact.description?.length > 200*/}
                                            {/*    ? `${contact.description.substring(0, 200)}...`*/}
                                            {/*    : contact.description}*/}
                                            Managing Partner, Kindred Ventures
                                        </div>
                                    </div>
                                </div>

                            </td>
                            <td className="px-4 py-2 w-3/10"> {/* 30% */}
                                <div className="pl-4">

                                    <div className="text-base font-medium text-gray-900">

                                        {contact.tags?.slice(0, 5).map((item: string) =>
                                            (
                                                <span className="px-2 py-1 bg-green-100 text-green-700 text-xs rounded-full">
                                                       {item}
                                                </span>
                                            ))}

                                        {/*@ts-ignore*/}
                                        {/*{contact.contacts?.address?.city}{contact.contacts?.address?.city && ','} {contact.contacts?.address?.country}*/}
                                    </div>
                                </div>
                            </td>
                            <td className="px-4 py-2 w-1/10"> {/* 10% */}
                                <div className="pl-4">
                                    {contact.cv.rank?.toFixed(2)}
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            {/*<div className="flex justify-between items-center p-4">*/}
            {/*    <PWButton type={'secondary'} loading={isLoading || paginationModel.page === 0}*/}
            {/*        onClick={() => setPaginationModel({ ...paginationModel, page: paginationModel.page - 1 })}*/}
            {/*        className={`px-4 py-2 bg-blue-500 text-white rounded ${paginationModel.page === 0 ? 'opacity-50' : ''}`}*/}
            {/*    >*/}
            {/*        Previous*/}
            {/*    </PWButton>*/}
            {/*    <PWButton type={'secondary'}*/}
            {/*        loading={isLoading || rowData.length < paginationModel.pageSize}*/}
            {/*        onClick={() => setPaginationModel({ ...paginationModel, page: paginationModel.page + 1 })}*/}
            {/*        className={`px-4 py-2 bg-blue-500 text-white rounded ${rowData.length < paginationModel.pageSize ? 'opacity-50' : ''}`}*/}
            {/*    >*/}
            {/*        Next*/}
            {/*    </PWButton>*/}
            {/*</div>*/}
            <Modal
                open={guestLimit}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="howTo-wrapper"
            >
                <div className="pt-20">
                    <LoginForm />
                </div>
            </Modal>
        </div>
    );
};

export default ContactTable;
