import React, {useEffect, useState} from 'react';
import { withProviders } from "./providers";
import {Routing} from "../pages";
import './index.scss';
// @ts-ignore
import {BehindApiClient, BehindApiUploadClient} from "@oleg/node-behind-api-client";
import {useDispatch, useSelector} from "react-redux";
import {accessKey, timeReportsKey} from "./reducers/accessReducer";
import WoololoApiClient from "../shared/lib/woololo-api-client";
import {setUser, setUserRole} from "./reducers/userReducer";
import {setAppReducer} from "./reducers/appReducer";
import faviconRefarg from '../shared/assets/img/refarg-favicon.png'
import faviconB2Bay from '../shared/assets/img/b2bay-favicon.png'
import { SocketActionTypes } from './reducers/webSocket'
import {getToken} from "../shared/services/token";
import {setCurrentCountry} from "./reducers/currentCompany";
import JsSIP from 'jssip'


function App() {
  const dispatch = useDispatch()

  async function getAuth(token:string) {
    if (token) {
      let woololoAPI = new WoololoApiClient(process.env.REACT_APP_API_WOOLOLO_ENDPOINT, token)
      try {
        let response:any = await woololoAPI.MeInfo();
        if (response) {
          dispatch(accessKey(token))
          dispatch(setUser(response))
          dispatch(setUserRole(response.Name === 'Noname' && response.Surname === 'Noname' ? 'guest' : 'user'))
          console.log(response.Name === 'Noname' && response.Surname === 'Noname' ? 'guest' : 'user')
          if (response.Name === 'Noname' && response.Surname === 'Noname') {

          } else {
            dispatch({ type: SocketActionTypes.SOCKET1_CONNECT, payload: {token: token} });
          }
            dispatch({ type: SocketActionTypes.SOCKET2_CONNECT, payload: {token: token} });
        }
      } catch (e) {
        localStorage.removeItem('access_token')
        console.log(e)
        await getToken()
      }
    }
  }

  useEffect(() => {
    // Connect the sockets when the component mounts

    return () => {
      // Disconnect the sockets when the component unmounts
      // dispatch({ type: SocketActionTypes.SOCKET1_DISCONNECT });
      // dispatch({ type: SocketActionTypes.SOCKET2_DISCONNECT });
    };
  }, [dispatch]);


  useEffect(() => {
    (async () => {
      let userName
      let password
      try {
        const cred = await behindAPI.SipPhoneGetWebRTCCredentials()
        if (cred.success === true) {
          userName = cred?.data[0]?.phone_webrtc_user_name
          password = cred?.data[0]?.phone_webrtc_password
          // @ts-ignore
          const socket = new JsSIP.WebSocketInterface(`wss://${process.env.REACT_APP_SIP_SERVER}:8089/ws`);
          const configuration = {
            sockets: [socket],
            uri: `sip:${userName}@${process.env.REACT_APP_SIP_SERVER}`,
            password: password,
          };
          // @ts-ignore
          ua = new JsSIP.UA(configuration);
          ua.on('connected', function(e:any){
            console.log('connected');
          });

          ua.on('registered', function(e:any){
            console.log('registered');
          });

          ua.on('unregistered', function(e:any){
            console.log('unregistered');
          });
          ua.on('registrationFailed', function(e:any){
            console.log('registrationFailed');
          });
          ua.start();
        }
      } catch (e) {

      }

      const token = await getToken()
      await getAuth(token)

      let link = document.querySelector("link[rel~='icon']")
      if (link !=null) {
        link = document.createElement('link')
        // @ts-ignore
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      const href = window.location.href;

      if (href.includes('sales.behind.ai') || href.includes('app.kundy.se')) {
        document.title = "Kundy";
        dispatch(setAppReducer('sales'));
        dispatch(setCurrentCountry('sweden'))
      } else if (href.includes('b2bay.ae')) {
        document.title = "B2Bay";
        // @ts-ignore
        link.href = faviconB2Bay; // Make sure 'link' is defined somewhere above this line.
        dispatch(setAppReducer('sales-uae'));
        dispatch(setCurrentCountry('uae'))
      } else if (href.includes('app.phonewise.ai') || href.includes('dev.raet.kundy.se')) {
        dispatch(setAppReducer('phonewise'));
      } else if (href.includes('raet.behind.ai') || href.includes('raet.kundy.se')) {
        dispatch(setAppReducer('raet'));
      } else {
        document.title = "Reports";
        // @ts-ignore
        link.href = faviconRefarg; // Make sure 'link' is defined somewhere above this line.
        dispatch(setAppReducer('reports'));
      }


    })();
    return () => {
      //dispatch({ type: SocketActionTypes.SOCKET1_DISCONNECT });
      //dispatch({ type: SocketActionTypes.SOCKET2_DISCONNECT });
    };
  }, []);

  useEffect(() => {
    const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");
    //console.log(prefersDarkScheme)
    if (prefersDarkScheme.matches) {
      //document.body.classList.add("theme-dark");
      document.body.classList.add('bg');
    } else {
      document.body.classList.remove("theme-dark");
      document.body.classList.add('bg');
    }
    return () => {
      document.body.classList.remove('bg');
    };
  }, []);

  return (
      <Routing />
  );
}

export default withProviders(App);
export const behindAPI = new BehindApiClient(process.env.REACT_APP_API_BEHIND_ENDPOINT, localStorage.getItem('access_token'))
export let ifTokenExpired = behindAPI.onExpired(() => localStorage.removeItem('access_token'))
export const behindAPIUpload = new BehindApiUploadClient()
export let ua:any