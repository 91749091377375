import React, {useEffect, useRef, useState} from 'react';
import './personCard.scss'
import {useWindowSize} from "../../shared/actions/windowSize";
import phoneIconWhite from "../../shared/assets/img/phone.png";
import emailIcon from "../../shared/assets/img/mail.png";
import homeIcon from "../../shared/assets/img/home.png";
import sendIcon from "../../shared/assets/img/icon-send-w.png";
import kanbanIcon from "../../shared/assets/img/kanban-icon.png";
import {Box, Menu, MenuItem, Modal} from "@mui/material";
import Fade from "@mui/material/Fade";
import {useNavigate} from "react-router-dom";
import {behindAPI, ua} from "../../app";
import {setChatId} from "../../app/reducers/chatReducer";
import {setSearchFormReducer} from "../../app/reducers/userSearch";
import linkIcon from "../../shared/assets/img/link.png";
import parse from "html-react-parser";
import fbIcon from "../../shared/assets/img/fb-icon.png";
import linkedinIcon from "../../shared/assets/img/linkedinIcon.png";
import instagramIcon from "../../shared/assets/img/instagramIcon.png";
import youtubeIcon from "../../shared/assets/img/youtubeIcon.png";
import vimeoIcon from "../../shared/assets/img/vimeoIcon.png";
import {getFlagEmoji} from "../../shared/lib/getFlagEmoji";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/reducers";
import settingIcon from "../../shared/assets/img/behind-editIcon.png";
import regenerateIcon from "../../shared/assets/img/behind-regenerateIcon.png";
import downloadIcon from "../../shared/assets/img/behind-downloadIcon.png";
import deleteIcon from "../../shared/assets/img/behind-icon-delete.png";
import Loader from "../../shared/ui/loader";
import {
    contactUploadStatusDispatcher,
    currentContactDispatcher,
    jdReportDispatcher
} from "../../app/reducers/currentContact";
import SipWidget from "../../widgets/sip/sip";
import {setSipContactDispatch} from "../../app/reducers/sipReducer";
import StringAvatar from "../../shared/lib/StringAvatar";
import PersonCardLogs from "./personCardLogs/personCardLogs";
import useKanban from "../../shared/useKanban";
import PWButton from "../../shared/ui/btn/PhonewiseButton";
import {
    LanguageIcon, BriefcaseIcon, AcademicCapIcon, ArrowUturnLeftIcon,
} from '@heroicons/react/24/outline';
import JobDescriptionReport from "./jobDescriptionReport";

interface Props {
    data: any;
    onEdit: (data: boolean) => void;
    onBack: (data: boolean) => void;
}

const PersonCard: React.FC<Props> = ({ data, onEdit, onBack }) => {
    const [primaryData, setPrimaryData] = useState({
        name: '',
        summary: '',
        outline: {
            detailedSummary: '',
            summaryEducation: '',
            summaryLanguageSkills: '',
            summarySkills: '',
            summaryWorkingExperience: ''
        },
        contacts: {
            emails: [],
            websites: [],
            phones: [],
            addresses: [{
                    country: '',
                    city: ''
                }],

        },
        languages: [
            {
                language: '',
                level: ''
            }
        ],
        education: [
            {
                degree: '',
                educationName: '',
                institutionName: '',
                startDate: '',
                endDate: '',
                others: [],
                location: {
                    country: '',
                    city: ''
                },
            }
        ],
        jobs: [
            {
                companyName: '',
                role: '',
                description: '',
                projectName: '',
                startDate: '',
                endDate: '',
                location: {
                    country: '',
                    city: ''
                },
                others: [],
                techSkills: [],
                responsibilities: []
            }
        ],
        softSkills: [],
        hardSkills: [],
        interests: [],
        certificates: [
            {
                date: '',
                description: '',
                institution: '',
                name: '',
                others: []
            }
        ]
    })
    const [otherData, setOtherData] = useState({})
    const size = useWindowSize()
    const dispatch = useDispatch()
    const [confModal, setConfModal] = useState(false)
    const { kanbanBoard, setKanbanBoard, updateKanbanBoard, getKanbanBoards, createKanbanBoard, getKanbanBoard, createKanbanCard } = useKanban();
    const person = useSelector((state: RootState) => state.contact.currentContactId)

    useEffect(() => {
        divideData(data)
        console.log(data)
    }, [data]);


    const divideData = (data:any) => {
        let primary = {};
        let other = {};

        Object.entries(data).forEach(([key, value]) => {
            switch (key) {
                case 'name':
                    primary = { ...primary, [key]: value };
                    break;
                case 'contacts':
                    primary = { ...primary, [key]: value };
                    break;
                case 'summary':
                    primary = { ...primary, [key]: value };
                    break;
                case 'outline':
                    primary = { ...primary, [key]: value };
                    break;
                case 'languages':
                    primary = { ...primary, [key]: value };
                    break;
                case 'jobs':
                    primary = { ...primary, [key]: value };
                    break;
                case 'education':
                    primary = { ...primary, [key]: value };
                    break;
                case 'softSkills':
                    primary = { ...primary, [key]: value };
                    break;
                case 'hardSkills':
                    primary = { ...primary, [key]: value };
                    break;
                case 'interests':
                    primary = { ...primary, [key]: value };
                    break;
                case 'certificates':
                    primary = { ...primary, [key]: value };
                    break;
                default:
                    other = { ...other, [key]: value };
                    break;
            }
        });

        // @ts-ignore
        setPrimaryData(primary)
        setOtherData(other)

        return;
    };

    const renderValue = (value: any, key: any) => {
        if (Array.isArray(value)) {
            return (
                <ul>
                    {value.map((v, i) => (
                        <li>
                            <span key={i}>{renderValue(v, '')}</span>
                        </li>

                    ))}
                </ul>
            );
        } else if (typeof value === 'object') {
            return (
                <div>
                    {Object.entries(value).map(([key, v]) => (
                        <div key={key}>
                            <label>{key}:</label>
                            {renderValue(v, '')}
                        </div>
                    ))}
                </div>
            );
        } else {
            return <span>{value}</span>;
        }
    };

    const renderContent = (key:any, value:any) => {
        return <><b>{key}</b>:{renderValue(value, key)}</>
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [menuIdentifier, setMenuIdentifier] = useState(null);
    const open = Boolean(anchorEl);
    //@ts-ignore
    const handleClick = (event, identifier) => {
        setAnchorEl(event.currentTarget);
        setMenuIdentifier(identifier); // Store the identifier in a state variable
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onConfModal = (value:boolean) => {
        setConfModal(value)
    }

    const navigate = useNavigate();
    const createChat = async (member:string) => {
        // @ts-ignore
        const res = await behindAPI.ChatChatCreateForIndividual(primaryData.name, member, '',  data._id)
        if (res.success === true) {
            dispatch(setChatId(res.data.chat_id))
            navigate("/chat/" + res.data.chat_id);
            dispatch(setSearchFormReducer(false))
            handleClose()
        }
    }

    const ContactsDropdown = () => {

        return <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open && menuIdentifier === 'contacts'}
            onClose={handleClose}
            TransitionComponent={Fade}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            {primaryData.contacts?.emails.map((list) => (
                <div className='chatWindow-left-menu-element'><MenuItem onClick={() => createChat(list)}>
                    {list}</MenuItem></div>
            ))}
        </Menu>
    }

    const [loader, setLoader] = useState({cv: false, original: false, outline: false, delete: false, rescan: false, toKanban: false})

    const downloadCV = async () => {
        setLoader(prevLoader => ({...prevLoader, cv: true}));
        const res = await behindAPI.RaetCvGenerate(data._id)
        if (res.success === true) {
            setLoader(prevLoader => ({...prevLoader, cv: false}));
            console.log(res.data)
            window.open(`https://api.behind.ai:6002/api/storage/10/file/${res.data.file_token}/download`, '_blank');
        }
    }

    const addToKanban = async () => {
        setLoader(prevLoader => ({...prevLoader, toKanban: true}));
        let boardId = await getKanbanBoards();
        if (boardId && boardId !== 'None') {

        } else {
            await createKanbanBoard();
        }
        console.log(boardId)
        console.log(kanbanBoard)
        const addCard = await createKanbanCard(boardId, {
            id: '',
            title: primaryData.name,
            content: primaryData.summary,
            data: {
                id: person,
                type: 'person',
            },
        });
        if (addCard) {
            console.log('Card added to kanban');
            setLoader(prevLoader => ({...prevLoader, toKanban: false}));
        } else {
            console.log('Card NOT added to kanban!');
        }
    }

    const downloadOriginal = async () => {
        setLoader(prevLoader => ({...prevLoader, original: true}));
        const res = await behindAPI.RaetCvObtain(data._id)
        if (res.success === true) {
            setLoader(prevLoader => ({...prevLoader, original: false}));
            window.open(`https://api.behind.ai:6002/api/storage/10/file/${res.data.file_token}/download`, '_blank');
        }
    }

    const reScanCV = async () => {
        setLoader(prevLoader => ({...prevLoader, rescan: true}));
        const res = await behindAPI.RaetCvReImportFromPdf(data._id)
        if (res.success === true) {
            setLoader(prevLoader => ({...prevLoader, rescan: false}));
            setPrimaryData(prevLoader => ({...prevLoader, ...res.data.cv}));
            dispatch(currentContactDispatcher((prevLoader:any) => ({...prevLoader, ...res.data.cv})))
            dispatch(contactUploadStatusDispatcher('success'));
        }
    }

    const deleteCV = async () => {
        setLoader(prevLoader => ({...prevLoader, delete: true}));
        const res = await behindAPI.RaetIndividualDelete(data._id)
        if (res.success === true) {
            setLoader(prevLoader => ({...prevLoader, delete: false}));
            dispatch(contactUploadStatusDispatcher('deleted'));
            onConfModal(false)
        }
    }

    const ActionsDropdown = () => {

        return <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open && menuIdentifier === 'actions'}
            onClose={handleClose}
            TransitionComponent={Fade}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            <div className='chatWindow-left-menu-element'>
                {/*<MenuItem onClick={() => onEdit(true)}><img className="functions-editIcon" src={regenerateIcon}/>&nbsp;&nbsp;&nbsp;Regenerate</MenuItem>*/}
                <MenuItem onClick={() => onEdit(true)}><img style={{zoom: '0.95'}} className="functions-editIcon" src={settingIcon}/>&nbsp;&nbsp;&nbsp;Edit</MenuItem>
                {/*<div style={{paddingLeft: '14px', paddingTop: '10px', opacity: 1}}><img className="functions-editIcon" style={{zoom: '1.15', right: '2px'}} src={downloadIcon}/>Download</div>*/}
                {/*<div>*/}
                {/*    <div>*/}
                {/*        CV*/}
                {/*    </div>*/}
                {/*    <div>*/}
                {/*        Original*/}
                {/*    </div>*/}
                {/*</div>*/}
                <MenuItem onClick={() => reScanCV()} >{loader.rescan ? <div style={{display: 'inline-block', position: 'relative', left: '5px', bottom: '3px'}}><Loader/></div> : <img className="functions-editIcon" style={{zoom: '1.15', right: '2px'}} src={regenerateIcon}/>}&nbsp;&nbsp;&nbsp;Re-scan CV</MenuItem>
                <MenuItem onClick={() => downloadCV()} >{loader.cv ? <Loader/> : <img className="functions-editIcon" style={{zoom: '1.15', right: '2px'}} src={downloadIcon}/>}&nbsp;&nbsp;&nbsp;Download CV</MenuItem>
                <MenuItem onClick={() => addToKanban()} >{loader.toKanban ? <Loader/> : <img className="functions-editIcon" style={{zoom: '1.15', right: '2px'}} src={kanbanIcon}/>}&nbsp;&nbsp;&nbsp;Add to kanban</MenuItem>
                <MenuItem onClick={() => downloadOriginal()}>{loader.original ? <Loader/> : <img className="functions-editIcon" style={{zoom: '1.15', right: '2px'}} src={downloadIcon}/>}&nbsp;&nbsp;&nbsp;Download original</MenuItem>
                <MenuItem onClick={()=>onConfModal(true)} >{loader.delete ? <Loader/> : <img className="functions-editIcon" style={{zoom: '1.15', right: '2px'}} src={deleteIcon}/>}&nbsp;&nbsp;&nbsp;Delete</MenuItem>
            </div>
        </Menu>
    }

    const getIcon = (label: any) => {
        let url = ''
        console.log(label)
        if (label === undefined) return linkIcon;
        if (label === '') return linkIcon;
        if (typeof label === 'object') {
            url = label?.url
        } else {
            url = label
        }
        if (url.includes('facebook.com')) {
            return fbIcon;
        } else if (url.includes('linkedin.com')) {
            return linkedinIcon;
        } else if (url.includes('instagram.com')) {
            return instagramIcon;
        } else if (url.includes('youtube.com')) {
            return youtubeIcon;
        } else if (url.includes('vimeo.com')) {
            return vimeoIcon;
        } else {
            return linkIcon;
        }
    };

    const onGetOutline = async() => {
        setLoader(prevLoader => ({...prevLoader, outline: true}));
        const res = await behindAPI.RaetCvUpdateOutline(data._id)
        console.log(res)
        if (res.success === true) {
            setLoader(prevLoader => ({...prevLoader, outline: false}));
            setPrimaryData(prevLoader => ({...prevLoader, outline: res.data}));
            dispatch(currentContactDispatcher((prevLoader:any) => ({...prevLoader, outline: res.data})))
        }
    }

    const [selectedTab, setSelectedTab] = useState('cv');

    // @ts-ignore
    const NavigationBar = () => {
        const onGetReport = async() => {
            const res = await behindAPI.RaetReportGet(project._id, contact._id)
            console.log(res)
            if (res.success === true) {
                dispatch(jdReportDispatcher(res.data))
                setSelectedTab('reports')
            }
        }
        return (
            <div className={'w-full flex justify-center'}>
                <div className="flex w-auto space-x-6 bg-gray-100 p-1 rounded-lg mt-4 mx-6 mr-11 justify-center">
                    {/* Job Description Tab */}
                    <div
                        className={`px-4 py-0 rounded-md font-medium cursor-pointer transition-all duration-300 ${
                            selectedTab === 'cv' ? 'bg-white text-black shadow-sm' : 'text-gray-700'
                        }`}
                        onClick={() => setSelectedTab('cv')}
                    >
                        CV
                    </div>
                    <div className="flex items-center py-2">
                        <div className="h-full border-r border-gray-300"></div>
                    </div>
                    <div
                        className={`px-4 py-0 rounded-md  font-medium cursor-pointer transition-all duration-300 ${
                            selectedTab === 'calls' ? 'bg-white text-black shadow-sm' : 'text-gray-700'
                        }`}
                        onClick={() => setSelectedTab('calls')}
                    >
                        Calls
                    </div>
                    <div className="flex items-center py-2">
                        <div className="h-full border-r border-gray-300"></div>
                    </div>
                    <div
                        className={`px-4 py-0 rounded-md  font-medium cursor-pointer transition-all duration-300 ${
                            selectedTab === 'outline' ? 'bg-white text-black shadow-sm' : 'text-gray-700'
                        }`}
                        onClick={() => setSelectedTab('outline')}
                    >
                        Outline
                    </div>
                    {/* Divider */}
                    <div className="flex items-center py-2">
                        <div className="h-full border-r border-gray-300"></div>
                    </div>
                    {/* Calls Tab */}
                    <div
                        className={`px-4 py-0 rounded-md  font-medium cursor-pointer transition-all duration-300 ${
                            selectedTab === 'reports' ? 'bg-white text-black shadow-sm' : 'text-gray-700'
                        }`}
                        onClick={() => onGetReport()}
                    >
                        Reports
                    </div>
                </div>
            </div>

        );
    };
    const contact = useSelector((state: RootState) => state.contact.contact);
    const project = useSelector((state: RootState) => state.raet.projectSelection);
    const jdReport = useSelector((state: RootState) => state.contact.jd_report);
    const onCreateReport = async() => {
        const res = await behindAPI.RaetReportGet(project._id, contact._id)
        console.log(res)
        if (res.success === true) {
            dispatch(jdReportDispatcher(res.data))
        }
    }


    return (
        <div>
            {data && <div className="companyCard" style={{
                marginRight: 0,
                paddingLeft: 10,
                paddingRight: 0,
                marginTop: (size.width && size.width < 840) ? 40 : 10
            }}>
                <div className="companyCard-header pt-5" style={size.width && size.width < 840 ? {flexDirection: 'column'} : {}}>
                    <div className="companyCard-name">
                        <div className='bg-gray-100 rounded-xl p-2 mr-4 cursor-pointer' onClick={() => onBack(true)}>
                            <ArrowUturnLeftIcon style={{width: 20}}/>
                        </div>
                        <div className='companyCard__stringAvatar'>
                            <StringAvatar name={primaryData.name}/>
                        </div>
                        <span>{primaryData.name}</span>
                    </div>
                    <div style={{display: "flex"}} className={'mr-12'}>
                        <PWButton type={'secondary'} onClick={(e) => handleClick(e, 'actions')}>...</PWButton>&nbsp;&nbsp;
                        <ActionsDropdown/>
                        {primaryData.contacts?.emails ?
                            <PWButton onClick={(e) => handleClick(e, 'contacts')}>Start chat<img src={sendIcon} style={{ width: '14px', opacity: '1', marginLeft: '7px', marginRight: '0px' }} /></PWButton> : <div></div>
                        }
                        <ContactsDropdown/>
                    </div>
                </div>
                <div className="companyCard-phone companyCard-line mx-12" style={{display:'flex', alignItems: 'center'}}>
                    {primaryData.contacts?.addresses?.length>0
                    &&
                    <>{primaryData.contacts?.addresses[0]?.city}{primaryData.contacts?.addresses[0]?.country && ', '+primaryData.contacts?.addresses[0]?.country}</>
                    }
                    {primaryData.contacts?.phones?.length > 0 &&
                    <>
                        &nbsp;|&nbsp;
                        <div className='companyCard-phone__element'>
                            {primaryData.contacts?.phones?.map((phone: string, index: number) => (
                                <div key={index} onClick={() => dispatch(setSipContactDispatch({phone: phone, name: primaryData.name, meta: {cv_id: data._id}}))}>
                                    <img className='companyCard-icon' src={phoneIconWhite} alt="Phone" />
                                    {phone}
                                </div>
                            ))}
                        </div>
                    </>
                    }
                </div>
                <NavigationBar/>
                {selectedTab === 'calls' &&
                    <PersonCardLogs/>
                }
                {selectedTab === 'outline' &&
                    <>
                        <div className="mx-12 mt-7">

                            {
                                primaryData.outline && Object.values(primaryData.outline).some(value => value !== '') ?
                                    <>
                                        <div className='text-base font-semibold'>Outline</div>
                                        {primaryData.outline && Object.entries(primaryData.outline).map(([key, value]) => (
                                            <p key={key} className='text-base leading-8 text-slate-700 mt-4'>
                                                {value}
                                            </p>
                                        ))}

                                    </>
                                    :
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <label style={{marginBottom: '0'}} className='text-base font-semibold'>Outline</label><br/>
                                        <button className='form-button' onClick={onGetOutline} disabled={loader.outline}>{loader.outline && <Loader/>}Generate</button>
                                    </div>
                            }
                        </div>
                    </>
                }
                {selectedTab === 'reports' &&
                    <>
                        {jdReport && Object.keys(jdReport).length > 0 ?
                            <div>
                                <JobDescriptionReport/>
                            </div>
                            :
                            <div className={'w-full text-center p-4 flex flex-col justify-center items-center'}>
                                <div><i>No reports yet...</i></div>
                                <div className={'mt-4'}>
                                    <PWButton
                                        onClick={() => onCreateReport()}
                                    >Create report</PWButton>
                                </div>
                            </div>
                        }
                    </>
                }
                {selectedTab === 'cv' &&
                    <>
                        <div className="companyCard-links companyCard-line mx-12">
                            {primaryData.contacts?.websites?.length>0 &&
                            <>
                                {
                                    primaryData.contacts?.websites?.map((item, index) => (
                                        <>
                                            <a style={{display: 'inline-block', top: '2px', position: 'relative'}} key={index} href={`https://${item}`} target="_blank" rel="noopener noreferrer">
                                                <img className='companyCard-icon' src={getIcon(item)}/>
                                                {/*// @ts-ignore*/}
                                                <span>{typeof item === 'object' ? item.url.replace(/^http:\/\/|https:\/\/|www./gm, '') : item.replace(/^http:\/\/|https:\/\/|www./gm, '')}</span>
                                            </a>
                                            &nbsp;|&nbsp;
                                        </>

                                    ))}
                            </>
                            }
                        </div>
                        <div className="companyCard-description mx-12">
                            <p style={{fontSize: 14, lineHeight: '24px'}}>
                                {primaryData.summary}
                            </p>
                        </div>






                        <div className="companyCard-line-container mx-12">
                            <div className='companyCard-line-col'>
                                <div className='mb-5 flex items-center'>
                                    <AcademicCapIcon style={{width: 30, display: 'inline-block'}}/><label className='form-label ml-2'>Education</label><br/>
                                </div>
                                {primaryData.education?.map((item, key) => (
                                    <div key={key+item.degree} style={{marginTop: '10px'}}>
                                        <div><b>{item.educationName}</b> <i>{item.degree && `(${item.degree})`}</i></div>
                                        <div>{item.institutionName}
                                            {(item.institutionName && (item.startDate || item.endDate)) && ', '}
                                            {(item.startDate || item.endDate) && item.startDate + ' - '+item.endDate}</div>
                                        <div>{item.others?.map((item: string) => item).join(', ')}</div>
                                    </div>
                                ))}
                            </div>
                            <div className='companyCard-line-col bg-amber-100 text-amber-800 rounded-lg py-5 px-10 leading-8'>
                                <div className='text-amber-800 mb-5 flex items-center'>
                                    <LanguageIcon style={{width: 50, display: 'inline-block'}}/><label className='form-label text-amber-800'>Languages</label><br/>
                                </div>

                                {primaryData.languages?.map((item) => (
                                    <span key={item.language} className={'text-base'}>
                            {item.language} <i>{item.level && `(${item.level})`}</i><br/>
                        </span>
                                ))}
                            </div>
                        </div>
                        {primaryData.certificates?.length > 0 &&
                        <div className="companyCard-line">
                            <label className='form-label'>Certificates</label><br/>
                            {primaryData.certificates?.map((item, key) => (
                                <div key={key+item.institution} style={{marginTop: '10px'}}>
                                    <div><b>{item.name}</b></div>
                                    <div>{item.institution}{(item.institution && item.date) && ', '}{item.date}</div>
                                    <div>{item.description}</div>
                                    <div>{item.others?.map((item: string) => item).join(', ')}</div>
                                </div>
                            ))}
                        </div>
                        }

                        <div className="companyCard-line-container">
                            <div className='companyCard-line-col'>
                                <label className='form-label'>Hard skills</label><br/>
                                <div>
                                    {primaryData.hardSkills?.map((item: string) => (
                                        <span key={item} style={{whiteSpace: 'nowrap'}}
                                              className="bg-purple-300 text-sm text-purple-800 rounded-xl px-2 py-1 mr-1 leading-8">
                                    {item}
                                </span>
                                    ))}
                                </div>
                            </div>
                            <div className='companyCard-line-col'>
                                <label className='form-label'>Soft skills</label><br/>
                                <div>
                                    {primaryData.softSkills?.map((item: string) => (
                                        <span key={item} style={{ whiteSpace: 'nowrap' }}
                                              className="bg-pink-300 text-sm text-pink-800 rounded-xl px-2 py-1 mr-1 leading-8">
                                        {item}
                                </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="companyCard-border"/>
                        <div className="companyCard-line">
                            <div className='mb-5 flex items-center'>
                                <BriefcaseIcon style={{width: 30, display: 'inline-block'}}/><label className='form-label ml-2'>Jobs</label><br/>
                            </div>
                            {primaryData.jobs?.map((item, key) => (
                                <div key={key+item.companyName}>
                                    <div style={{marginTop: '15px', fontWeight:'bold', fontSize: '16px', textDecoration:'underline'}}><b>{item.companyName}</b></div>
                                    <div><b>{item.projectName}</b></div>
                                    <div>{item.role}</div>
                                    <div><i>{item?.location?.city && `(${item?.location?.city})`}{(item?.location?.country && item?.location?.city) && ', '}{item?.location?.country && item?.location?.country} </i></div>
                                    <div><i>{item?.startDate} - {item.endDate}</i></div>
                                    <div  style={{marginTop: '10px',  fontSize: '15px'}} >
                                        {item?.description?.length > 0 &&
                                        <div> {item.description}</div>
                                        }
                                    </div>
                                    <div>
                                        {item?.responsibilities?.length > 0 && <div style={{marginTop: '10px', fontWeight:'bold'}}>Responsibilities</div>}
                                        <ul>
                                            {item?.responsibilities?.map((item: string) =>
                                                (
                                                    <>
                                                        <li>{item}</li>
                                                    </>
                                                ))}
                                        </ul>

                                    </div>
                                    <div>
                                        {item?.techSkills?.length > 0 && <div style={{marginTop: '10px', fontWeight:'bold'}}>Skills</div>}

                                        {item?.techSkills?.map((item: string) =>
                                            (
                                                <span key={item} style={{whiteSpace: 'nowrap'}}
                                                      className="bg-gray-300 text-sm text-gray-800 rounded-xl px-2 py-1 mr-1 leading-8">
                                                {item}
                                             </span>
                                            ))}


                                    </div>
                                    {/*<div>{item.others?.map((item: string) => item).join(', ')}<br/></div>*/}
                                </div>
                            ))}
                        </div>
                        {primaryData.interests?.length > 0 &&
                        <div className="companyCard-line">
                            <label className='form-label'>Interests</label><br/>
                            <div>{primaryData.interests?.map((item: string) => item).join(', ')}<br/></div>
                        </div>}
                        {Object.entries(otherData).map(([key, value]) => (
                            <div key={key}>
                                {renderContent(key,value)}
                            </div>

                        ))}
                    </>
                }
            </div>}
            <div>
                <Modal
                    open={confModal}
                    //onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="howTo-wrapper"
                >
                    <Box
                        style={{padding: '26px 40px', borderRadius: '12px'}}
                        sx={{
                            backgroundColor: 'rgba(255,255,255,1)',
                        }}
                        className="settings-box confirmation-box">
                        <div className="form-line"><div className='box-text'>
                            Are you sure you want to delete CV?
                        </div></div>
                        <div className="form-line"
                             style={{display: 'flex', justifyContent: 'space-between', paddingTop: '20px'}}>
                            <button className='form-button' onClick={() => onConfModal(false)}>No</button>
                            <button className='form-primaryButton-delete' onClick={deleteCV}>Yes</button>
                        </div>
                    </Box>
                </Modal>
            </div>
        </div>
    );
};

export default PersonCard;