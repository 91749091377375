const SET_SIP_CONTACT = "SET_SIP_CONTACT"
const SET_CALL_STATUS = "SET_CURRENT_PROMPT"
const defaultState = {
    contact: {},
    callStatus: 'init'

}

export default function sipReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_SIP_CONTACT:
            return {
                ...state,
                contact: action.payload
            }
        case SET_CALL_STATUS:
            return {
                ...state,
                callStatus: action.payload
            }
        default:
            return state
    }
}

export const setSipContactDispatch = (contact) => ({type: SET_SIP_CONTACT, payload: contact})
export const setCallStatusDispatch = (callStatus) => ({type: SET_CALL_STATUS, payload: callStatus})