import {applyMiddleware, combineReducers, createStore} from "redux"
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from "redux-thunk"
import { Middleware } from 'redux';
import userReducer from "./userReducer";
import userDataReducer from "./userDataReducer";
import appearanceReducer from './appearanceReducer'
import companyDataReducer from "./currentCompany";
import promptReducer from "./userPrompt";
import accessReducer from "./accessReducer";
import appReducer from "./appReducer";
import chatReducer from "./chatReducer";
import timeReportsAppearanceReducer from "./timeReports/appearanceReducer";
import searchReducer from "./userSearch";
import chatBotReducer from "./chatBotReducer";
import { socket1Reducer, socket2Reducer, SocketState, socket1Middleware, socket2Middleware  } from './webSocket';
import contactDataReducer from "./currentContact";
import sipReducer from "./sipReducer";
import raetReducer from "./raet/raetReducer";

const middleware: Middleware[] = [thunk, socket1Middleware, socket2Middleware];

export interface AppState {
    socket1: SocketState;
    socket2: SocketState;
}

const rootReducer = combineReducers({
    user: userReducer,
    records: userDataReducer,
    appearance: appearanceReducer,
    company: companyDataReducer,
    contact: contactDataReducer,
    prompt: promptReducer,
    accessKey: accessReducer,
    app: appReducer,
    timeReportsAppearance: timeReportsAppearanceReducer,
    chat: chatReducer,
    chatBot: chatBotReducer,
    search: searchReducer,
    socket1: socket1Reducer,
    socket2: socket2Reducer,
    sip: sipReducer,
    raet: raetReducer,
})

export const store = createStore(rootReducer,
    composeWithDevTools(applyMiddleware(...middleware))
)
export type RootState = ReturnType<typeof rootReducer>;