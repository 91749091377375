const SET_TAB_SELECTION = "SET_TAB_SELECTION"
const SET_JOB_DESCRIPTION_MODE = "SET_JOB_DESCRIPTION_MODE"
const SET_PROJECT_SELECTION = "SET_PROJECT_SELECTION"
const SET_JOB_DESCRIPTION = "SET_JOB_DESCRIPTION"

const defaultState = {
    tabSelection: 'project',
    jobDescriptionMode: 'overview',
    projectSelection: null,
    jobDescription: null,
}

export default function raetReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_TAB_SELECTION:
            return {
                ...state,
                tabSelection: action.payload,
            }
        case SET_JOB_DESCRIPTION_MODE:
            return {
                ...state,
                jobDescriptionMode: action.payload,
            }
        case SET_PROJECT_SELECTION:
            return {
                ...state,
                projectSelection: action.payload,
            }
        case SET_JOB_DESCRIPTION:
            return {
                ...state,
                jobDescription: action.payload,
            }
        default:
            return state
    }
}

export const setTabSelection = tabSelection => ({type: SET_TAB_SELECTION, payload: tabSelection})
export const setJobDescriptionMode = jobDescriptionMode => ({type: SET_JOB_DESCRIPTION_MODE, payload: jobDescriptionMode})
export const setProjectSelection= projectSelection => ({type: SET_PROJECT_SELECTION, payload: projectSelection})
export const setJobDescription = jobDescription => ({type: SET_JOB_DESCRIPTION, payload: jobDescription})