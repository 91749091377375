import React from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../../app/reducers";
import {
    UserGroupIcon,
    BriefcaseIcon,
    HeartIcon,
    CheckCircleIcon,
    ListBulletIcon,
    AcademicCapIcon,
    CommandLineIcon,
    CodeBracketIcon,
    GlobeAltIcon,
    CubeTransparentIcon,
    LanguageIcon
} from '@heroicons/react/24/outline';

interface Requirement {
    data: string;
    question: string;
}

interface JobDescription {
    Name: string;
    Role: string;
    Job: string;
    Culture: string;
    MainRequirements: Requirement[];
    Responsibilities: string[];
    NaturalLanguages: string[];
    RequiredHardSkills: Requirement[];
    RecommendedHardSkills: Requirement[];
    SoftSkills: Requirement[];
    CodingLanguages: Requirement[];
    Frameworks: Requirement[];
    OperatingSystems: Requirement[];
    CulturalSkills: Requirement[];
}

const JobDescriptionOverview = () => {
    const project = useSelector((state: RootState) => state.raet.projectSelection);

    if (!project || !project.jd) {
        return <div>No project selected</div>;
    }

    const { jd } = project;

    return (
        <>
            {Object.keys(jd).length>0 &&
            <div className="px-8">
                <section className="mb-8">
                    <h2 className="text-xl font-semibold mb-2 flex items-center">
                        <BriefcaseIcon className="h-7 w-7 text-blue-500 mr-2" />
                        Job
                    </h2>
                    <p className="text-gray-700">{jd?.Job}</p>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold mb-2 flex items-center">
                        <GlobeAltIcon className="h-7 w-7 text-blue-500 mr-2" />
                        Culture
                    </h2>
                    <p className="text-gray-700">{jd?.Culture}</p>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold mb-2 flex items-center">
                        <CheckCircleIcon className="h-7 w-7 text-blue-500 mr-2" />
                        Main Requirements
                    </h2>
                    <ul className="list-disc list-inside text-gray-700">
                        {jd?.MainRequirements?.map((req: { data: string }, index: number) => (
                            <li key={index}>{req.data}</li>
                        ))}
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold mb-2 flex items-center">
                        <ListBulletIcon className="h-7 w-7 text-blue-500 mr-2" />
                        Responsibilities
                    </h2>
                    <ul className="list-disc list-inside text-gray-700">
                        {jd?.Responsibilities?.map((resp: string, index: number) => (
                            <li key={index}>{resp}</li>
                        ))}
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold mb-2 flex items-center">
                        <LanguageIcon className="h-7 w-7 text-blue-500 mr-2" />
                        Languages
                    </h2>
                    <ul className="list-disc list-inside text-gray-700">
                        {jd?.NaturalLanguages?.map((skill: { data: string }, index: number) => (
                            <li key={index}>{skill.data}</li>
                        ))}
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold mb-2 flex items-center">
                        <AcademicCapIcon className="h-7 w-7 text-blue-500 mr-2" />
                        Required Hard Skills
                    </h2>
                    <ul className="list-disc list-inside text-gray-700">
                        {jd?.RequiredHardSkills?.map((skill: { data: string }, index: number) => (
                            <li key={index}>{skill.data}</li>
                        ))}
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold mb-2 flex items-center">
                        <AcademicCapIcon className="h-7 w-7 text-blue-500 mr-2" />
                        Recommended Hard Skills
                    </h2>
                    <ul className="list-disc list-inside text-gray-700">
                        {jd?.RecommendedHardSkills?.map((skill: { data: string }, index: number) => (
                            <li key={index}>{skill.data}</li>
                        ))}
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold mb-2 flex items-center">
                        <UserGroupIcon className="h-7 w-7 text-blue-500 mr-2" />
                        Soft Skills
                    </h2>
                    <ul className="list-disc list-inside text-gray-700">
                        {jd?.SoftSkills?.map((skill: { data: string }, index: number) => (
                            <li key={index}>{skill.data}</li>
                        ))}
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold mb-2 flex items-center">
                        <CodeBracketIcon className="h-7 w-7 text-blue-500 mr-2" />
                        Coding Languages
                    </h2>
                    <ul className="list-disc list-inside text-gray-700">
                        {jd?.CodingLanguages?.map((lang: { data: string }, index: number) => (
                            <li key={index}>{lang.data}</li>
                        ))}
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold mb-2 flex items-center">
                        <CubeTransparentIcon className="h-7 w-7 text-blue-500 mr-2" />
                        Frameworks
                    </h2>
                    <ul className="list-disc list-inside text-gray-700">
                        {jd?.Frameworks?.map((framework: { data: string }, index: number) => (
                            <li key={index}>{framework.data}</li>
                        ))}
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold mb-2 flex items-center">
                        <CommandLineIcon className="h-7 w-7 text-blue-500 mr-2" />
                        Operating Systems
                    </h2>
                    <ul className="list-disc list-inside text-gray-700">
                        {jd?.OperatingSystems?.map((os: { data: string }, index: number) => (
                            <li key={index}>{os.data}</li>
                        ))}
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold mb-2 flex items-center">
                        <GlobeAltIcon className="h-7 w-7 text-blue-500 mr-2" />
                        Cultural Skills
                    </h2>
                    <ul className="list-disc list-inside text-gray-700">
                        {jd?.CulturalSkills?.map((skill: { data: string }, index: number) => (
                            <li key={index}>{skill.data}</li>
                        ))}
                    </ul>
                </section>
            </div>
            }
        </>

    );
};

export default JobDescriptionOverview;